import React from "react";
import "./App.css";
import Logo from "../assets/logo.svg";
import Album1 from "../assets/album.jpeg";
import { Link } from "react-scroll";
import VideoComponent from "./VideoComponent"; // import css

const sources = [
  require("../../src/assets/Videos/IMG_2349.mp4"),
  require("../../src/assets/Videos/IMG_2652.mp4"),
  require("../../src/assets/Videos/IMG_9108.mp4"),
  require("../../src/assets/Videos/T2.mp4"),
  require("../../src/assets/Videos/T13.mp4"),
  require("../../src/assets/Videos/T15.mp4")
];
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      currentTime: 0,
      showVideo: false
    };

    this.newShortcuts = [
      // Press number 1 to jump to the postion of 10%
      {
        keyCode: 49, // Number 1
        // handle is the function to control the player
        // player: the player's state
        // actions: the player's actions
        handle: (player, actions) => {
          const duration = player.duration;
          // jump to the postion of 10%
          actions.seek(duration * 0.1);
        }
      },
      {
        keyCode: 39, // Right arrow
        ctrl: true, // Ctrl/Cmd
        handle: (player, actions) => {}
      },
      {
        keyCode: 39, // Right arrow
        ctrl: true, // Ctrl/Cmd
        handle: (player, actions) => {}
      }
    ];
  }

  buttonClick = index => {
    switch (index) {
      case 0:
        this.setState({ current: 0 });
        break;
      case 1:
        this.setState({ current: 1 });
        break;
      case 2:
        this.setState({ current: 2 });
        break;
      case 3:
        this.setState({ current: 3 });
        break;
      case 4:
        this.setState({ current: 4 });
        break;
      case 5:
        this.setState({ current: 5 });
        break;
      default:
        break;
    }
  };

  keyHandling = e => {
    console.log("Key code: " + e.keyCode);

    if (e.keyCode === 39) {
      this.setState({
        current: this.state.current === 5 ? 5 : this.state.current + 1
      });
    } else if (e.keyCode === 37) {
      this.setState({
        current: this.state.current === 0 ? 0 : this.state.current - 1
      });
    }
  };

  changeCurrentTime = seconds => {
    // const { player } = this.player.getState();
    // this.player.seek(seconds);
    // this.player.seek(player.currentTime + seconds);
  };

  componentDidMount() {
    window.addEventListener("keyup", this.keyHandling);
  }

  closeClick = () => {
    this.setState({ showVideo: false });
  };
  handleChange = currentTime => {
    this.setState({ currentTime });
  };
  render() {
    console.log("in render", this.state.currentTime);
    return (
      <div>
        <React.Fragment>
          {sources.map((item, index) => {
            return (
              <VideoComponent
                closeClick={this.closeClick}
                buttonClick={this.buttonClick}
                length={sources}
                currentTime={this.state.currentTime}
                handleChange={this.handleChange}
                source={item}
                show={this.state.current === index}
              />
            );
          })}
        </React.Fragment>
        <div className="container">
          {/* !this.state.showVideo ? (
            <section id="header" name="home">
              <div className="navbar">
                <div>
                  <Link to="home" spy={true} smooth={true} duration={1000}>
                    Ana sayfa
                  </Link>
                </div>
                <div>
                  <Link to="records" spy={true} smooth={true} duration={1000}>
                    Kayıtlar
                  </Link>
                </div>
                <div>
                  <img src={Logo} height="80" alt="frozen clouds" />
                </div>
                <div>
                  <Link to="spotify" spy={true} smooth={true} duration={1000}>
                    SPOTİFY
                  </Link>
                </div>
                <div>
                  <Link to="contact" spy={true} smooth={true} duration={1000}>
                    İletişim
                  </Link>
                </div>
              </div>
              <div
                className="play-button"
                onClick={() => this.setState({ showVideo: true })}
              />
            </section>
          ) : null */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <section id="records" name="records">
            <h2>360 KAYITLAR</h2>
            <div className="records">
              <div tabIndex="0" onKeyDown={this.keyHandling} className="record">
                <img src={Album1} alt="frozen clouds" />
                <h3>Album name</h3>
              </div>
              <div className="record">
                <img src={Album1} alt="frozen clouds" />
                <h3>Album name</h3>
              </div>
              <div className="record">
                <img src={Album1} alt="frozen clouds" />
                <h3>Album name</h3>
              </div>
            </div>
          </section>
          <section id="spotify" name="spotify">
            <div>
              <h3>
                SPOTİFY’DA BİZİ TAKİP EDEBİLİR YENİLİKLERDEN <br /> HABERDAR
                OLABİLİRSİNİZ
              </h3>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://open.spotify.com/artist/0M3LK8CPC8luVRntRVHwAp"
              >
                <button className="btn">Spotify'da dinle</button>
              </a>
            </div>
          </section>
          <footer name="contact">
            <div>
              <img src={Logo} height="80" alt="frozen clouds" />
            </div>
            <div className="icons">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/frozencloudsband/"
              >
                <i className="icon-facebook" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/frozencloudstr/?hl=tr"
              >
                <i className="icon-instagram" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.youtube.com/user/FrozenCloudsTR"
              >
                <i className="icon-youtube-play" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://frozenclouds.bandcamp.com/"
              >
                <i className="icon-bandcamp" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://open.spotify.com/artist/0M3LK8CPC8luVRntRVHwAp"
              >
                <i className="icon-spotify" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://music.apple.com/us/album/palm-tree-oblivion"
              >
                <i className="icon-apple" />
              </a>
            </div>
            <div className="copyright">
              © Copyright 2019 | All Rights Reserved
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
