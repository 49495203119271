import React from "react";
import "./video-react.css";
import { Player, BigPlayButton,ControlBar,PlaybackRateMenuButton } from "video-react";

export default class VideoComponent extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            currentTime: 0,
            current:0
        };

        this.newShortcuts = [
            // Press number 1 to jump to the postion of 10%
            {
                keyCode: 49, // Number 1
                // handle is the function to control the player
                // player: the player's state
                // actions: the player's actions
                handle: (player, actions) => {
                    const duration = player.duration;
                    // jump to the postion of 10%
                    actions.seek(duration * 0.1);
                }
            },
            {
                keyCode: 39, // Right arrow
                ctrl: true, // Ctrl/Cmd
                handle: (player, actions) => {

                }
            },
            {
                keyCode: 39, // Right arrow
                ctrl: true, // Ctrl/Cmd
                handle: (player, actions) => {

                }
            }
        ];

    }
    componentDidMount() {
        // subscribe state change
        console.log("componentDidMount")
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
            this.changeCurrentTime(this.props.currentTime)

    }

    changeCurrentTime = (seconds)=> {
        console.log("changeCurrentTime")
        const { player } = this.player.getState();
        this.player.seek(seconds);
        this.player.play()
    }


    handleStateChange(state, prevState) {
        // copy player state to this component's state

        if(state.currentSrc === prevState.currentSrc){
            // console.log("handleStateChange",state,prevState)
            if(state.currentTime !== prevState.currentTime){
                this.props.handleChange(state.currentTime)
            }
        }else{
            console.log("vide changede")
        }


        // this.setState({
        //     player: state,
        //     currentTime: state.currentTime
        // });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log("will receive props",this.props.source ,nextProps.source)
        // if(this.props.source !== nextProps.source){
        //     this.changeCurrentTime(this.props.currentTime)
        // }
    }

    render(){
        let {source,show,length,buttonClick,closeClick} = this.props
        return(

            <div className={"player-container " + (show ? 'show' : 'hidden')}>


                <Player
                    autoplay={true}
                    preload={"metadata"}
                    muted={!this.props.show}
                    ref={player => {
                        this.player = player;
                    }}

                >
                    <source src={source} />
                    <ControlBar autoHide={false} />
                </Player>
                {/*<ul>*/}
                {/*    {*/}
                {/*        length.map((item,index)=>{*/}
                {/*            return(*/}
                {/*                <li onClick={()=>this.props.buttonClick(index)}>{index+1}</li>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}

                {/*</ul>*/}
            </div>

        )
    }
}
